.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-modal .login-modal-container {
  background-color: white;
  border: 1px solid #fbb116;
  border-radius: 20px;
  padding: 20px;
  width: 400px;
}

.login-modal .login-modal-container .login-modal-header {
  border: 1px solid #fbb116;
  border-radius: 16px;
}

.login-modal .login-modal-container .login-modal-header ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.login-modal .login-modal-container .login-modal-header ul li {
  list-style: none;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: #482aff;
  cursor: pointer;
  padding: 10px;
  background-color: #fbb116;
  border-radius: 16px;
  cursor: pointer;
}

.login-modal .login-modal-container .login-modal-header ul .login-active,
.login-modal .login-modal-container .login-modal-header ul .register-active
 {
  background-color: white;
}


.login-modal .login-modal-container .register-form,
.login-modal .login-modal-container .login-basket-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.login-modal .login-modal-container .register-form input,
.login-modal .login-modal-container .login-basket-form input {
  border-radius: 16px;
  border: 1px solid #482aff;
  padding: 10px;
}

.login-modal .login-modal-container .register-form input:nth-last-child(2),
.login-modal .login-modal-container .login-basket-form input:nth-last-child(2) {
  margin-top: 16px;
}

.login-modal .login-modal-container .login-basket-form .login-submit-btn,
.login-modal .login-modal-container .register-form .register-submit-btn {
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}

.login-modal .login-modal-container .login-basket-form .login-submit-btn button,
.login-modal .login-modal-container .register-form .register-submit-btn button {
  border: none;
  border-radius: 16px;
  background-color: #fbb116;
  color: #482aff;
  width: 180px;
  padding: 10px;
  font-size: 20px;
}


.login-modal .login-modal-container .login-footer{
  margin-top: 41px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.login-modal .login-modal-container .login-footer span{
  font-size: 16px;
  color: #CCCCCC;
  font-weight: 200;
}

.login-modal .login-modal-container .login-footer span:last-child{
  color: #482aff;
  border-bottom: 1px solid #CCCCCC;
  cursor: pointer;
}




