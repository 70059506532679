.basket-data {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.basket-data .basket-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.basket-data .basket-product .basket-img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 15px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #999;
}

.basket-data .basket-product .basket-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.basket-data .basket-product .basket-content p {
  margin: 0;
  color: #482aff;
  font-size: 16px;
}

.basket-data .basket-btn {
    display: flex;
}

.basket-data .basket-btn button {
  border: none;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  width: 126px;

}

.basket-data .basket-btn .more {
  background-color: #5ccdff;
  margin-left: 10px;
}

.basket-data .basket-btn .delete {
  background-color: #f03b2a;
}

.count-btns {
  display: flex;
  align-items: center;
}

.count-btns button {
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #007bff;
  margin: 0 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.count-btns button:hover {
  background-color: #0056b3;
}


.basket-page .basket-accept-btn{
  display: flex;
  justify-content: flex-end;
}

.basket-page .basket-accept-btn .accept-btn{
  background-color: #FBB116;
    border-radius: 10px;
    padding: 8px 16px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border: none;
}