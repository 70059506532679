/* main-admin-page */

.main-Dashboard{
  padding-top: 20px;
  display: flex;
  height: 100vh;
  width: 100%;
  border: 1px solid;
  position: fixed;
}



/* admin nav */

.admin-nav ul {
  width: 100%;
}

.admin-nav ul li{
  position: relative;
}

.admin-nav ul li svg{
  width: 20px !important;
  fill: white;
}

.admin-nav ul li span{
  position: absolute;
  top: -10px;
  right: -8px;
  color: red;
  font-size: larger;
  cursor: pointer;
}


.admin-nav ul li div{
  border: 1px solid black;
  position: fixed;
  width: 200px;
  height: auto;
  right: 70px;
  top: 80px;
  background-color:#482AFF;
  border-radius: 20px;
  z-index: 10;
  padding: 10px;
}

.admin-nav ul li div p{
  margin: 5px;
  font-size: 15px;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  cursor: pointer;
}


.admin-nav ul li div p:nth-of-type(1){
  color: #ff952b;
}

.admin-nav ul li div p:nth-of-type(2){
  color: #5ccdff;
}

.admin-nav ul li:nth-of-type(1){
  margin: auto auto auto 20px !important;
} 

/*  */

.main-admin-page .col-md-12{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-admin-page .col-md-6{
  margin: 20px 0px;
}

.parent-table-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.parent-table-box > p{
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  font-size: 30px;
  color: blue;
  font-family: "Nunito";
}

.table-box  {
  border-radius: 20px;
  padding: 10px;
  border: 2px dashed rgb(77, 63, 63);
  margin: 10px;
  width: 45%;
}

.table-box p{
  border-bottom: 2px solid rgb(160, 150, 150);
}

.table-box div:nth-of-type(1) {
  border-bottom: 1px solid;
  margin-bottom: 15px;
}

.table-box div:nth-of-type(1) > div{
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid;
  margin: 5px;
  border-radius: 5px;
  background-color: green;
  color: white;
}


.table-box div:nth-of-type(1) > div span{
  color: red;
  cursor: pointer;
}


.btn-div{
  width: 100%;
  display: flex;
  justify-content: space-between;
} 

.btn-div  button{
  border-radius: 10px;
  border: none;
  padding: 4px 20px;
  font-size: large;
  color: white;
}

.btn-div button:nth-child(1){
  background-color: green;
}

.btn-div button:nth-child(2){
  background-color: red;
} 


.mainContainer{
  width: 75%;
}

/* create-page */


.sideContainer{
  border: 1px solid;
  width: 25%;
  height: 100%;
}

.sideContainer p{
  margin: 10px 10px auto;
  font-size: 25px;
}

.create-page{
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  /* ju */
}

.return{
  position: fixed;
  top: 100px;
  left: 20px;
  border: 1px solid;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: blue;
}
.return a{
  text-decoration: none;
}

.return span{
  font-size: 30px;
  color: white;
}

/* login-form */
.login-form{
  margin-top: 30px;
}

.login-form form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:100px;
}



.login-form
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  margin: 10px;
  width: 50%;
  height: 60px;
}

@media(max-width:768px){
  .login-form
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100%;
  }
}


.login-form button{
  height: 40px;
  margin: 10px;
  border-radius: 10px;
  width: 100px;
}



.table tbody tr {
  text-align: center;
}

.table{
  width: 50%;
}

.create-button{
  position: fixed;
  border: 3px solid rgb(53, 43, 43);
  top: 11%;
  right: 50px;
  padding: 0px 18px;
  border-radius: 20px;
  font-size: 25px;
  color: red;
  background-color: unset;
}

.create-div{
  width: 100%;
  margin: 20px;
}

.create-div select{
  margin: 20px;
}

/* create data */

.create-admin{
  text-align: center;
}

.create-admin form{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.select-div{
  position: relative;
}

.create-admin .students{
  position: absolute;
  border: 1px solid;
  background-color: black;
  width: 100px;
  height: auto;
  z-index: 1;
  color: white;
}

.create-admin .students p{
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding: 10px 5px;
}

.create-admin .students p span{
  cursor: pointer;
  color: red;
  padding: 5px ;
}


.create-admin form label input{
  margin-left: 5px;
}

.create-admin button{
  border: none;
  background-color: blue;
  border-radius: 20px;
  color: white;
  padding: 5px 20px;
  margin: 10px;
}

.create-admin button:hover{
  color: black;
}


.create-admin input{
  border-radius: 20px;
}

/* create timetable */
.info-table{
  border: 2px dashed;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
}

.info-table p{
  border-bottom: 1px solid rgb(160, 150, 150);
}

.info-table button{
  padding: 5px 15px;
  margin: 10px 0px 10px 20px;
  border-radius: 20px;
  font-weight: 700;
  border: none;
  background-color: chartreuse;
}

.create-data{
  width: 100%;
  height: auto;
  text-align: center;
}

.create-data select{
  width: 100px;
  text-align: center;
  border-radius: 20px;
  font-weight: 600;
  padding: 5px;
  background-color: white;
  margin: 10px;
}



/* create class */

.class-Name{
  overflow: scroll;
}

.create-class{
  width: 100%;
}


.create-class form{
  margin: 30px auto;
}

.classes{
  height: 0%;
  border: 1px solid;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
  display: flex;
}

/* create student */

.create-student{
  width: 100%;
  text-align: center;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0px;
}

.create-student form{
  width: 48%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 20px;
}

/* .create-student label{
  margin: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
} */

.create-student label input{
  width: 50%;
  border: none;
  border-bottom: 1px solid;
  outline: none !important;
}


.birthDay{
  width: 48%;
  border: 1px solid;
  height: 600px;
  margin: 10px auto;
  border-radius: 20px;
}

/* create student */

.create-teacher{
  text-align: center;
}

/* create-teacher */

.create-teacher form{
  width: 100%;
  margin: 30px auto;
  height: auto;
  flex-wrap: wrap;
}

/* create box */

.create-box{
  text-align: center;
  margin: 5px auto !important;
  width: 20%;
  border: 1px solid;
  padding: 10px;
  border-radius: 20px;
}


.create-box h3{
  font-size: 20px;
}
/* teacher page */



/* get date */

.get-date{
  width: 100%;
  border: 2px dashed  blue;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}


.get-date
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 300px;
  margin: 20px;
}


.get-date
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root{
  margin: 20px;
}

/* .get-date label{
  margin: 10px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
} */
